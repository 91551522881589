'use client';
import { ProductDocument } from '@models/product';
import { onSnapshot } from 'firebase/firestore';
import { useMemo, useState, useEffect } from 'react';
import { getProductsByIdQuery } from '@util/firestore/products';
import { useAuth } from 'context/AuthContext';

const useRealTimeProducts = (ids: string[]) => {
  const { userDoc } = useAuth();
  const [realTimeProducts, setProducts] = useState<ProductDocument[]>([]);
  const productsQuery = useMemo(
    () => getProductsByIdQuery(ids.length ? ids : ['']),
    [ids]
  );
  useEffect(() => {
    if (!userDoc) return;
    const unsubscribe = onSnapshot(productsQuery, (snapshot) => {
      const realTimeData = snapshot.docs.map((doc) => doc.data());
      setProducts(realTimeData);
    });
    return () => {
      unsubscribe();
    };
  }, [productsQuery, userDoc]);

  return {
    realTimeProducts,
  };
};

export default useRealTimeProducts;
