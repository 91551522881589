import BaseDrawer from '@c/drawers/BaseDrawer';
import { CartIcon, CloseIcon, PlusIcon } from '@c/icons';
import CloseButton from '@c/icons/buttons/CloseButton';
import { useQuery } from '@tanstack/react-query';
import Button from '@ui/Button';
import SafeImage from '@ui/SafeImage';
import { functions, httpsCallable } from '@util/firebase';
import { formatCurrency, getCartItemFromProductDocument } from '@util/index';
import { getProductSlug } from '@util/urlHelpers';
import { useAuth } from 'context/AuthContext';
import { useShoppingCart } from 'context/ShoppingCartContext';
import { useToastContext } from 'context/ToastContext';
import useMyFeed from 'features/homepage/hooks/useMyFeed';
import { ProductDocument } from 'models/product';
import Link from 'next/link';
import { Fragment } from 'react';
import CartProduct from './CartProduct';

interface ShoppingCartProps {}

const useUpsell = ({ product_ids }: { product_ids: string[] }) => {
  const { userDoc } = useAuth();

  return useQuery({
    queryKey: ['upsellProducts', ...product_ids],
    queryFn: async () => {
      const { data } = await httpsCallable<
        { product_ids: string[] },
        { products: ProductDocument[] }
      >(
        functions,
        'v2_getCartUpsell'
      )({
        product_ids,
      });
      return data.products;
    },
    enabled: !!userDoc,
  });
};

const ShoppingCart = ({}: ShoppingCartProps) => {
  const {
    cartOpen,
    setCartOpen,
    cart,
    realTimeProducts,
    removeFromCart,
    increaseCartQty,
    removeCoupon,
  } = useShoppingCart();

  const { showSuccessToast } = useToastContext();

  const { data: upsellProducts, isFetching } = useUpsell({
    product_ids: cart?.product_ids ?? [],
  });

  const { data: youMayAlsoLike, isLoading } = useMyFeed();

  if (!cart) return null;

  const getCartItem = (productId: string) =>
    cart.items.find((i) => i.product_id === productId);

  const groupProductsBySeller = (products: ProductDocument[]) => {
    return products.reduce<Record<string, ProductDocument[]>>(
      (acc, product) => {
        const sellerId = product.seller_id;
        const sellerProducts = acc[sellerId] || [];
        sellerProducts.push(product);
        acc[sellerId] = sellerProducts;
        return acc;
      },
      {}
    );
  };

  const groupedProducts = groupProductsBySeller(realTimeProducts);

  return (
    <BaseDrawer
      show={cartOpen}
      position={'right'}
      dismiss={() => setCartOpen(false)}
      fill={true}
    >
      <>
        <div className="flex h-[85vh] w-full flex-col ">
          <div className="relative flex w-full border-b  border-zinc-200">
            <div className="flex h-[6rem] w-full items-center justify-center">
              <h1 className="text-[1.8rem] font-semibold">CART</h1>
            </div>

            <button
              onClick={() => setCartOpen(false)}
              className="absolute right-[2rem] top-[1.7rem]"
            >
              <CloseIcon width={20} height={20} />
            </button>
          </div>

          {realTimeProducts.length > 0 ? (
            <>
              <div className="flex flex-col gap-y-12 overflow-y-auto overflow-x-hidden p-8">
                {Object.values(groupedProducts).map((products, index) => (
                  <Fragment key={index}>
                    {/* <div className="mb-8 flex w-full items-center justify-between">
                      <Link
                        href={`/profile/${seller?.username}`}
                        onClick={() => setCartOpen(false)}
                        className="flex items-center gap-x-4"
                      >
                        {seller?.name === 'MXLocker' ? (
                          <div className="flex h-16 w-16 items-center justify-center rounded-full bg-zinc-100 p-2">
                            <MxIcon />
                          </div>
                        ) : (
                          <div className="h-12 w-12 rounded-full">
                            <Image
                              src={seller?.photo ?? ''}
                              alt={seller?.username ?? ''}
                              className="aspect-square h-full w-full rounded-full object-cover"
                              height={200}
                              width={200}
                            />
                          </div>
                        )}

                        <span className="text-[1.6rem] font-medium text-black">
                          {isSellerLoading
                            ? 'Loading...'
                            : seller
                            ? seller.username
                            : 'MXLocker'}
                        </span>

                        {seller?.is_verified && <ShieldCheckIcon />}
                      </Link>
                    </div> */}
                    {products.map((product, productIndex) => (
                      <CartProduct key={product.id} product={product} />
                    ))}
                    <hr className="-mx-8 text-zinc-200" />
                  </Fragment>
                ))}
                {isFetching || isLoading ? (
                  <div className="flex w-full flex-col gap-y-6 px-8 pb-16 pt-4">
                    <h3 className="h-[2.2rem]  rounded-lg  text-[1.8rem] font-semibold">
                      You may also need
                    </h3>
                    <div className="flex flex-nowrap overflow-x-scroll p-4 scrollbar-none">
                      <div className="flex min-w-[75vw] items-center justify-between gap-4 rounded-[3rem] pr-2 pt-2 sm:w-full sm:min-w-0">
                        <div className="h-[10rem] w-[10rem] animate-pulse rounded-[3rem] bg-gray-500" />

                        <div className="flex grow flex-col gap-y-1">
                          <h3 className="line-clamp-2 h-[2.2rem] w-[10rem] animate-pulse whitespace-pre-wrap rounded-lg bg-gray-500 text-[1.6rem]"></h3>

                          <p
                            className={
                              'h-[2.2rem] w-[5rem] animate-pulse rounded-lg bg-gray-500 text-[1.6rem] font-bold'
                            }
                          ></p>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : upsellProducts?.length ||
                  youMayAlsoLike?.gear.length ||
                  youMayAlsoLike?.parts.length ? (
                  <div className="flex w-full flex-col gap-y-6 px-8 pb-16 pt-4">
                    <h3 className="text-[1.8rem] font-semibold text-zinc-800">
                      You may also need
                    </h3>
                    <div className="flex flex-nowrap gap-8 overflow-x-scroll p-4 scrollbar-none">
                      {[
                        ...(upsellProducts ?? []),
                        ...(youMayAlsoLike?.gear ?? []),
                        ...(youMayAlsoLike?.parts?.[0]?.products ?? []),
                      ].map((p) => {
                        return (
                          <div
                            className="flex min-w-[75vw] items-center justify-between gap-4  pr-2 pt-2 shadow-md sm:w-full sm:min-w-0"
                            key={p.id}
                          >
                            <Link
                              href={getProductSlug(p)}
                              onClick={() => setCartOpen(false)}
                            >
                              <SafeImage
                                src={p.thumbnail}
                                alt={p.title}
                                className="aspect-square h-[10rem] w-[20rem] object-contain"
                                height={200}
                                width={200}
                              />
                            </Link>

                            <Link
                              href={getProductSlug(p)}
                              onClick={() => setCartOpen(false)}
                              className="flex grow flex-col gap-y-1"
                            >
                              <h3 className="line-clamp-2 whitespace-pre-wrap text-[1.6rem] text-black">
                                {p.title}
                              </h3>

                              <p
                                className={'text-[1.6rem] font-bold text-black'}
                              >
                                {formatCurrency(p.price)}
                              </p>
                            </Link>
                            <button
                              className="z-10 flex items-center justify-center rounded-full bg-black px-4 py-4 font-semibold text-white"
                              onClick={(e) => {
                                e.stopPropagation();
                                increaseCartQty(
                                  getCartItemFromProductDocument(p)
                                );

                                showSuccessToast(
                                  'An item in your cart has been updated'
                                );
                              }}
                            >
                              <PlusIcon />
                            </button>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : null}
              </div>

              <div className="flex flex-col items-center justify-center gap-y-6 px-8 pt-4">
                <p className="w-[40rem] text-center text-zinc-400">
                  Shipping and taxes are calculated at checkout after confirming
                  your address
                </p>

                <Link
                  href={'/checkout'}
                  className="flex w-full items-center justify-center rounded-[2rem] bg-[#C41618] py-6 text-[2rem] font-semibold text-white"
                  onClick={() => {
                    setCartOpen(false);
                  }}
                >
                  <div className="flex items-center gap-x-2">
                    <span>CHECKOUT</span>
                  </div>
                </Link>
              </div>
            </>
          ) : (
            <div className="flex h-[70rem] items-center justify-center">
              <div className="flex flex-col items-center gap-y-8">
                <CartIcon className="h-32 w-32 text-zinc-400" />

                <h3 className="text-[1.8rem] font-medium text-black">
                  Your cart is empty!
                </h3>

                <Button
                  text="Continue shopping"
                  className="!rounded-[2rem] !py-12 text-[1.8rem] sm:rounded-lg sm:py-4"
                  type="secondary"
                  href={'/shop/all'}
                  onClick={() => setCartOpen(false)}
                />
              </div>
            </div>
          )}
        </div>
      </>
    </BaseDrawer>
  );
};

export default ShoppingCart;
