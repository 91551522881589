import { cva, VariantProps } from 'class-variance-authority';
import { AnimatePresence } from 'framer-motion';
import { MotionDiv } from 'motion';
import { useEffect } from 'react';

interface DrawerProps extends VariantProps<typeof drawerStyles> {
  dismiss: () => void;
  show: boolean;
  children: React.ReactNode;
  position?: 'left' | 'right' | 'bottom';
  fill?: boolean;
}

const drawerStyles = cva('fixed bg-brand-white overflow-clip', {
  variants: {
    position: {
      left: 'left-0 h-screen',
      right: 'right-0 h-screen',
      bottom: '!bottom-0 left-0 h-fit w-full rounded-t-3xl',
    },
    top: {
      0: 'top-0',
      inherit: 'top-inherit',
    },
    fill: {
      true: 'w-full',
      false: 'w-full max-w-[80vw] sm:max-w-[50rem]',
    },
  },
  defaultVariants: {
    position: 'left',
    top: 0,
    fill: false,
  },
});

const BaseDrawer = ({
  show,
  dismiss,
  children,
  position = 'left',
  top,
  fill,
}: DrawerProps) => {
  useEffect(() => {
    if (show) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [show]);

  const topStyle = top === 0 ? 'top-0' : 'top-inherit';

  const getInitialTransform = () => {
    switch (position) {
      case 'right':
        return { x: '+100%' };
      case 'left':
        return { x: '-100%' };
      case 'bottom':
        return { y: '+100%' };
      default:
        return { x: '-100%' };
    }
  };

  return (
    <div className="fixed z-[60]">
      <AnimatePresence>
        {show && (
          <MotionDiv
            key="drawer"
            initial={getInitialTransform()}
            animate={{ x: 0, y: 0 }}
            exit={getInitialTransform()}
            transition={{ duration: 0.3 }}
            className={`${drawerStyles({ position, top, fill })} z-10`}
          >
            {children}
          </MotionDiv>
        )}
      </AnimatePresence>
      {/* dimmer overlay */}
      <AnimatePresence>
        {show && (
          <MotionDiv
            className={`fixed left-0 top-0 h-full w-full bg-brand-black ${topStyle}`}
            onClick={() => {
              dismiss();
            }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.5 }}
            exit={{ opacity: 0 }}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default BaseDrawer;
