// TODO While framer motion types do not support React 19 / Nextjs 15 yet, we can use this to type the motion components. Eventually upgrade framer motion to 12 once out of beta.

import { motion, type MotionProps } from 'framer-motion';

// Define types for different HTML elements with motion props
type MotionDivProps = MotionProps &
  React.HTMLAttributes<HTMLDivElement> & { ref?: React.Ref<HTMLDivElement> };
type MotionButtonProps = MotionProps &
  React.HTMLAttributes<HTMLButtonElement> & { type?: 'button' | 'submit' };
type MotionSpanProps = MotionProps & React.HTMLAttributes<HTMLSpanElement>;
type MotionTrProps = MotionProps & React.HTMLAttributes<HTMLTableRowElement>;
// Export typed motion components
export const MotionDiv = motion.div as React.FC<MotionDivProps>;
export const MotionButton = motion.button as React.FC<MotionButtonProps>;
export const MotionSpan = motion.span as React.FC<MotionSpanProps>;
export const MotionTr = motion.tr as React.FC<MotionTrProps>;
